.optionCard {
  margin-top: 1%;
  //   margin-left: 8%;
  //   margin-right: 8%;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e6e8ec;
  max-width: 80ch;
  @media only screen and (max-width: 440px) {
    margin-top: 10%;
    height: auto;
  }
}

.optionCardParagraph {
  color: #777e90;
  padding-left: 4%;
  //   padding-top: 1%;
  font-weight: 400;
  font-size: 20px;
  font-family: "Inter";
  font-style: normal;
  margin: 0%;
  @media only screen and (max-width: 820px) {
    padding-top: 5%;
  }
  @media only screen and (max-width: 440px) {
    padding-top: 2%;
  }
}

.optionCardRadio {
  color: #777e90;
  padding-left: 4%;
  @media only screen and (max-width: 820px) {
    padding-top: 5%;
  }
  @media only screen and (max-width: 440px) {
    padding-top: 2%;
  }
}

pre.codesnippet {
  background: white;
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
  margin-left: 3%;
  max-width: 80ch;
  font-size: large;
}

pre code {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  //   color: aqua;
}

.reliabilityButton {
  width: 105%;
  font-size: 120%;
  background-color: #0a71b9;
}
.reliabilityButton1 {
  width: 105%;
  font-size: 120%;
  background-color: #5a5a5c;
}
