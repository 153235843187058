.believabilityContainer {
  background-color: #f6f8fc;
  height: 88vh;
}

.headerGridItem {
  height: 8vh;
  background-color: #0a71b9;
  @media only screen and (max-width: 820px) {
    height: 6vh;
  }
  @media only screen and (max-width: 440px) {
    height: 6vh;
  }
}

.headerPara {
  color: #ffffff;
  font-style: Inter;
  font-size: 24px;
  font-weight: 400;
  margin-top: -1px;

  @media only screen and (max-width: 820px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 440px) {
    font-size: 18px;
  }
}

.headerCaption {
  margin-top: -1.5%;
  color: #fff;
  font-style: Inter;
  font-size: 16px;
  font-weight: 400;

  @media only screen and (max-width: 820px) {
    font-size: 12px;
    margin-top: -2px;
  }
  @media only screen and (max-width: 440px) {
    font-size: 10px;
    margin-top: -2px;
  }
}

.selectPara {
  margin-top: 3%;
  margin-left: 4px;
  margin-bottom: 2px;
  font-size: 14px;
  font-style: Inter;
  font-weight: 400;
  color: #002c44;
}

.believabilityLeftGrid {
  margin-left: 5%;
  margin-right: 5%;
}

.selectedPara {
  margin-top: 0%;
  margin-bottom: 1%;
  font-size: 24px;
  font-weight: 400;
}
