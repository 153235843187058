.tbl {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .tdh  {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .trn:nth-child(even) {
    background-color: #dddddd;
  }