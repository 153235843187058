.signInContainer {
  background-image: url("../../assets/LandingScreenForVideo.jpg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  // background-color: none;
  margin: 0%;
  padding: 0%;
  height: 100vh;
  @media only screen and (max-width: 1104px) {
    background-image: none;
    height: 100vh;
  }
}

.headerPara {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
}

// .signinContentPaper{
//   margin-top: 15%;
// }
