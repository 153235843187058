.landing{
    // background-image: url("../assets/LandingScreenForVideo.jpg");
    // background-size: cover;
    // position: relative;
    // /* width: 100vh; */
    // height: 100vh;
    // background-repeat: no-repeat;
    // background-position: center;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .LandingVideoGrid{
    margin-top: 10%;
    margin-left: 5%;
    @media only screen and (max-width: 910px) {
      margin-top: 0%;
      margin-left: 0%;
    }
  }

  .landinCard{
    margin: 8%;
    margin-top: 14%;
    background-color: #0A71B9;
    height: 75%;
    @media only screen and (max-width: 440px) {
      height: 95%;
      margin: 0%;
      margin-top: 14%;
    }
  }

  .cardHeading{
    color: #0A71B9;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    margin: 1% ;
    margin-top: 3%;

    // line-height: 48px;
  }

  .cardParagraph{
    top: 2%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin: 1% ;
    // line-height: 29px;
  }

  .candidateCard{
    margin-top: 6%;
    margin-left: 8%;
    margin-right: 8%;
    height: 40px;
    background: #5A5A5C;
    border-radius: 10px;
    @media only screen and (max-width: 440px) {
      margin-top: 10%;
      height: 40px;
    }
  }

  .candidateCardParagraph{
    color: #FFFFFF;
    padding-left: 4%;
    padding-top: 4%;
    font-size: 20px;
    font-family: 'Inter';
    font-style: normal;
    margin: 0%;
    @media only screen and (max-width: 820px) {
      padding-top: 5%;
    }
    @media only screen and (max-width: 440px) {
      padding-top: 2%;
    }
  }
