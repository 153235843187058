.signInContainer {
  background-image: url("../../assets/LandingScreenForVideo.jpg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-color: none;
  height: 100vh;
  margin: 0%;
  padding: 0%;
  @media only screen and (max-width: 1104px) {
    background-image: none;
  }
}
